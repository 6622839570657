.Business {
    padding-inline: 100px;
    padding-block: 55px;
    border-radius: 15px;
}

@media only screen and (max-width: 480px) {
    .Business {
        padding-block: 40px;
        padding-inline: 20px;  
    }
}

@media only screen and (max-width: 960px){
    .Business {
        padding-block: 30px;
        padding-inline: 40px;
    }
}