.InTheMedia {
    padding-inline: 100px;
    padding-block: 55px;
}

.Media-card:hover {
    box-shadow: 0 0 11px rgba(33,33,33,.2); 
}

@media only screen and (max-width: 768px) {
    .InTheMedia {
        padding-top: 0px;
        padding-bottom: 40px;
        padding-inline: 20px;
    }

    .Media-card {
        max-width: 330px;
    }
} 

@media only screen and (max-width: 960px) and (min-width: 768px){
    .InTheMedia {
        padding-top: 0px;
        padding-bottom: 30px;
        padding-inline: 40px;
    }
}