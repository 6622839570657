.FAQ {
    padding-inline: 100px;
    padding-block: 55px;
}

@media only screen and (max-width: 768px) {
    .FAQ {
        padding-block: 40px;
        padding-inline: 20px;
    }
} 

@media only screen and (max-width: 960px) and (min-width: 768px){
    .FAQ {
        padding-block: 30px;
        padding-inline: 40px;
    }
}
