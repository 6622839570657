
.MobileApp {
  background-color: #464646;
  border-radius: 15px;
  padding-inline: 100px;
  padding-block: 55px;
}

@media only screen and (max-width: 480px) {
  .MobileApp {
    padding-inline: 20px;
  }
}
@media only screen and (max-width: 960px) {
  .MobileApp {
    padding-block: 30px;
    padding-inline: 40px;
  }
}
