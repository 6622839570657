@import "../../App.css";

.Hero {
    min-height: 80vh;
    padding-inline: 100px;
}

.star-icon {
    min-width: 18px;
}

.Hero-container {
    min-height: 80vh
}

@media only screen and (max-width: 768px) {
    .Hero {
        padding-inline: 20px;
    }
}

@media only screen and (max-width: 960px) and (min-width: 768px) {
    .Hero {
        padding-inline: 40px;
    }
}

