@import "../../App.css";

.Footer {
    padding-block: 55px;
    padding-inline: 100px;
}

.Footer-logo {
    height: 40px;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}


@media only screen and (max-width: 768px) {
    .Footer {
        padding-block: 40px;
        padding-inline: 20px;
    }
} 

@media only screen and (max-width: 960px) and (min-width: 768px){
    .Footer {
        padding-block: 30px;
        padding-inline: 40px;
    }
}

